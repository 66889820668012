(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

if (typeof Object.assign !== 'function') {
  // Must be writable: true, enumerable: false, configurable: true
  Object.defineProperty(Object, "assign", {
    value: function assign(target, varArgs) {
      // .length of function is 2
      'use strict';

      if (target === null || target === undefined) {
        throw new TypeError('Cannot convert undefined or null to object');
      }

      var to = Object(target);

      for (var index = 1; index < arguments.length; index++) {
        var nextSource = arguments[index];

        if (nextSource !== null && nextSource !== undefined) {
          for (var nextKey in nextSource) {
            // Avoid bugs when hasOwnProperty is shadowed
            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
              to[nextKey] = nextSource[nextKey];
            }
          }
        }
      }

      return to;
    },
    writable: true,
    configurable: true
  });
}

document.addEventListener('DOMContentLoaded', function () {
  'use strict';

  (function () {
    $('select#teacher-lang').select2({
      theme: 'bootstrap'
    });
  })();

  function sortSelectOptions(selector, skip_first) {
    var options = skip_first ? $(selector + ' option:not(:first)') : $(selector + ' option');
    var arr = options.map(function (_, o) {
      return {
        t: $(o).text(),
        v: o.value,
        s: $(o).prop('selected')
      };
    }).get();
    arr.sort(function (o1, o2) {
      var t1 = o1.t.toLowerCase(),
          t2 = o2.t.toLowerCase();
      return t1 > t2 ? 1 : t1 < t2 ? -1 : 0;
    });
    options.each(function (i, o) {
      o.value = arr[i].v;
      $(o).text(arr[i].t);

      if (arr[i].s) {
        $(o).attr('selected', 'selected').prop('selected', true);
      } else {
        $(o).removeAttr('selected');
        $(o).prop('selected', false);
      }
    });
  }

  sortSelectOptions('#subject-subject_id_0', true);
  /* FIX */

  $(document).on('keydown', 'form', function (e) {
    if (e.keyCode == 13) {
      e.preventDefault();
    }
  });
  var $mainSlider = $('.main-slider');

  if ($mainSlider.length) {
    $mainSlider.owlCarousel({
      items: 1,
      mouseDrag: true,
      nav: true,
      navText: ['', ''],
      autoplay: true,
      loop: true,
      rewindNav: true,
      dots: false
    });
  } // Делаем не кликабельными 2 пункта верхнего меню


  var $topMenu = $('.top-menu-list');
  var linkList = $topMenu.find('> li').eq(1).find('> a').add($topMenu.find('> li').eq(2).find('> a'));
  linkList.each(function () {
    $(this).on('click', function (e) {
      e.preventDefault();
    });
  });
  /* Разворачивающейся меню третьего левела */

  $(document).on('click', '.top-menu-list .level-02.root > a', function (e) {
    $(this).next().slideToggle(300);
    e.preventDefault();
  }); // Адативная всплывающая форма

  var $activateButton = $('.contacts-callout'),
      $formPopup = $('.form-popup'),
      $formFade = $('.form-fade'),
      $formClose = $('.form-popup-close'),
      $formWrap = $('.form-popup-wrap'),
      thisPlaceholder,
      fadeTimeout = 300;

  var activatePopupForm = function activatePopupForm(activateButton, formPopup, formFade, formWrap) {
    activateButton.on('click', function () {
      formPopup.add(formFade).addClass('is-visible form-fade-in');
      formWrap.addClass('is-flex');
      setTimeout(function () {
        formPopup.add(formFade).removeClass('form-fade-in');
      }, fadeTimeout);
    });
    formFade.add($formClose).on('click', function () {
      formPopup.add(formFade).addClass('form-fade-out');
      setTimeout(function () {
        formPopup.add(formFade).removeClass('is-visible form-fade-out');
        formWrap.removeClass('is-flex');
      }, fadeTimeout);
    });
  };

  activatePopupForm($activateButton, $formPopup, $formFade, $formWrap); // Сменяющиеся плейсхолдеры для формы

  $formPopup.find('input').add($formPopup.add('textarea')).focus(function () {
    thisPlaceholder = $(this).attr('placeholder');
    $(this).data('placeholder', thisPlaceholder);
    $(this).attr('placeholder', '');
  }).blur(function () {
    thisPlaceholder = $(this).data('placeholder');
    $(this).attr('placeholder', thisPlaceholder);
  }); // Значения даты для формы

  var currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 1);
  var currentDay = currentDate.getDate();
  var currentYear = currentDate.getFullYear();
  var currentMonth = currentDate.getMonth();
  $('#day').val(currentDay);
  $('#year').val(currentYear);
  $('#month').find('option').eq(currentMonth).attr('selected', 'selected'); // date and datetime pickers

  $(document).ready(function () {
    $('.date').datepicker({
      format: "yyyy-mm-dd",
      language: myTutor.language.cultureKey,
      pickerPosition: "bottom-left",
      autoclose: true
    });
    $('.datetime').datetimepicker({
      format: "yyyy-mm-dd hh:ii:ss",
      language: myTutor.language.cultureKey,
      pickerPosition: "bottom-left",
      autoclose: true
    });
    $('.clockpicker').clockpicker({
      autoclose: true
    });
    /* ajax загрузка изображений */

    $('.ajax-image-uploader').each(function (i, elem) {
      $(elem).find('.ajax-image-file').on('change', function () {
        $(elem).find('.ajax-image-name').val($(this).val().split("/").pop().split("\\").pop());
        var files = this.files;
        var data = new FormData();
        data.append('action', 'upload/photo');
        $.each(files, function (key, value) {
          data.append(key, value);
        }); // Отправляем запрос

        $(elem).find('.ajax-image-name').attr('style', 'background-image: linear-gradient(to right, #a8e8a8, #a8e8a8);' + 'background-size: 0% 100%;' + 'background-repeat: no-repeat;');
        $.ajax({
          url: '/ajax',
          type: 'POST',
          data: data,
          cache: false,
          dataType: 'json',
          //async: false,
          xhr: function xhr() {
            var xhr = new window.XMLHttpRequest(); // прогресс загрузки на сервер

            xhr.upload.addEventListener("progress", function (evt) {
              if (evt.lengthComputable) {
                var percentComplete = evt.loaded / evt.total * 100; //$(elem).find('.upload-percent').css('width', percentComplete + '%');

                $(elem).find('.ajax-image-name').css('background-size', percentComplete + '% 100%');
              }
            }, false); // прогресс скачивания с сервера

            xhr.addEventListener("progress", function (evt) {
              if (evt.lengthComputable) {
                var percentComplete = evt.loaded / evt.total; // делать что-то...

                console.log(percentComplete);
              }
            }, false);
            return xhr;
          },
          processData: false,
          // Не обрабатываем файлы (Don't process the files)
          contentType: false,
          // Так jQuery скажет серверу что это строковой запрос
          success: function success(respond, textStatus, jqXHR) {
            if (typeof respond.error === 'undefined') {
              if (respond instanceof Array || respond.length == 1) {
                $(elem).find('.ajax-image-value').val(respond[0]);
                $('img[data-uploader="#' + $(elem).attr('id') + '"]').attr('src', respond[0]);
              }
            } else {
              console.log('ОШИБКИ ОТВЕТА сервера: ' + respond.error);
            }
          },
          error: function error(jqXHR, textStatus, errorThrown) {
            console.log('ОШИБКИ AJAX запроса: ' + textStatus);
          }
        });
      });
    });
    /* ajax загрузка документов */

    $('.ajax-file-uploader').each(function (i, elem) {
      $(elem).find('.ajax-file').on('change', function () {
        var fileName = $(this).val().split("/").pop().split("\\").pop();
        $(elem).find('.ajax-file-name').val(fileName);
        var files = this.files;
        var data = new FormData();
        data.append('action', 'upload/photo');
        var fileNames = [];
        $.each(files, function (key, value) {
          data.append(key, value);
          fileNames.push(value.name);
        });
        $(elem).find('.ajax-file-name').attr('style', 'background-image: linear-gradient(to right, #a8e8a8, #a8e8a8);' + 'background-size: 0% 100%;' + 'background-repeat: no-repeat;'); // Отправляем запрос

        $.ajax({
          url: '/ajax',
          type: 'POST',
          data: data,
          cache: false,
          dataType: 'json',
          //async: false,
          xhr: function xhr() {
            var xhr = new window.XMLHttpRequest(); // прогресс загрузки на сервер

            xhr.upload.addEventListener("progress", function (evt) {
              if (evt.lengthComputable) {
                var percentComplete = evt.loaded / evt.total * 100; //$(elem).find('.upload-percent').css('width', percentComplete + '%');

                $(elem).find('.ajax-file-name').css('background-size', percentComplete + '% 100%');
              }
            }, false); // прогресс скачивания с сервера

            xhr.addEventListener("progress", function (evt) {
              if (evt.lengthComputable) {
                var percentComplete = evt.loaded / evt.total; // делать что-то...

                console.log(percentComplete);
              }
            }, false);
            return xhr;
          },
          processData: false,
          // Не обрабатываем файлы (Don't process the files)
          contentType: false,
          // Так jQuery скажет серверу что это строковой запрос
          success: function success(respond, textStatus, jqXHR) {
            if (typeof respond.error === 'undefined') {
              if (respond instanceof Array) {
                /*$(elem).find('.ajax-image-value').val(respond[0]);*/
                var $results = $('*[data-uploader="#' + $(elem).attr('id') + '"]');
                var resultTpl = $(elem).find('.document-template').html();
                var fileError = [];
                var docOffset = $results.find('.document-item').length;
                respond.forEach(function (item, i) {
                  if (item == "") {
                    fileError.push(fileNames[i]);
                    return;
                  }

                  var $resultTpl = $('<div/>').addClass('document-item').addClass('form-group').html(resultTpl);
                  $resultTpl.find('input[name="documentname"]').attr('name', 'document[ ' + (i + docOffset) + ' ][name]').val(fileNames[i]);
                  $resultTpl.find('input[name="documenturi"]').attr('name', 'document[ ' + (i + docOffset) + ' ][uri]').val(item);
                  $resultTpl.find('.document-link').attr('href', "/download.php?file=" + item).html(fileNames[i]);
                  $resultTpl.find('.document-remove').on('click', function () {
                    $(this).parents('.document-item').remove();
                    return false;
                  });
                  $results.append($resultTpl);
                });
              }
            } else {
              console.log('ОШИБКИ ОТВЕТА сервера: ' + respond.error);
            }
          },
          error: function error(jqXHR, textStatus, errorThrown) {
            console.log('ОШИБКИ AJAX запроса: ' + textStatus);
          }
        });
      });
    });
    $(document).on('click', '.document-item .document-remove', function () {
      $(this).parents('.document-item').remove();
      return false;
    });
    /* ajax формы фильтрации */

    $(document).on('submit', '.ajaxFilter', function () {
      var url = $(this).attr('action');
      var filterContain = $(this).attr('data-filter-contain');
      var resultContain = $(this).attr('data-result-contain');
      var gets = {};
      var newUrl = location.href.split('?');

      if (newUrl.length > 1) {
        newUrl = newUrl[0];
        newUrl[1].split('&').forEach(function (g) {
          if (g) {
            g = g.split('=');
            gets[g[0]] = g.length > 1 ? g[1] : "";
          }
        });
      } else {
        if (newUrl.length == 1) {
          newUrl = newUrl[0];
        } else {
          newUrl = '';
        }
      }

      $(this).find('select,teaxtarea,input,checkbox,radiobox').each(function (i, inp) {
        var val = $(inp).val();
        var name = $(inp).attr('name');
        delete gets[name];

        if (val) {
          gets[name] = val;
        }
      });
      var getstr = "";

      for (var k in gets) {
        getstr += "&" + k + "=" + gets[k];
      }

      if (getstr !== "") {
        newUrl = url + "?" + getstr.substr(1);
      }

      $.ajax({
        url: url,
        data: gets,
        method: 'GET',
        success: function success(html) {
          var $filter = $(html).find(filterContain);
          var seoURL = $filter.attr('data-seo-url');
          $(filterContain).html($filter.html());
          $(filterContain).attr('data-seo-url', seoURL);
          $(resultContain).html($(html).find(resultContain).html());

          if (seoURL) {
            newUrl = seoURL;
          }

          history.pushState(null, document.title, newUrl);
        }
      });
      return false;
    });
    $("[data-toggle='tooltip']").tooltip();
    $(document).on('click', '.action-remove', function () {
      var removeItem = $(this).attr('data-remove');
      $(removeItem).remove();
      return false;
    });
    $(document).on('click', '.action-remove-all', function () {
      var removeItem = $(this).attr('data-remove');
      var conditionItem = $(this).attr('data-condition');
      $(removeItem).each(function (i, elem) {
        if ($(elem).find(conditionItem).length) {
          $(elem).remove();
        }
      });
    });
    $(document).on('click', '.action-select-all', function () {
      var selectItems = $(this).attr('data-select-all');

      if ($(this).is(':checked')) {
        $(selectItems).attr('checked', true);
      } else {
        $(selectItems).removeAttr('checked');
      }
    });
    $(document).on('click', '.action-add', function () {
      var id = 0;
      $('.service-item').each(function (i, elem) {
        var _id = $(elem).attr('id').split('-').pop();

        if ($.isNumeric(_id) && _id > id) id = _id;
      });
      id++;
      var $row = $(myTutor.chunks.teacherUserPanel.service).attr('id', 'service-' + id).appendTo($('#service-list'));
      $row.find('input[name="services_id[]"]').attr('name', 'services_id[new_' + id + ']').val('new_' + id);
      $row.find('input[name="services_name[]"]').attr('name', 'services_name[new_' + id + ']').val('Новая услуга');
      $row.find('input[name="services_price[]"]').attr('name', 'services_price[new_' + id + ']').val('0');
      $row.find('input[name="services_active[]"]').attr('name', 'services_active[new_' + id + ']').val('1');
      $row.find('.action-remove').attr('data-remove', '#service-' + id);
      return false;
    });
    $(document).on('click', '.action-add-education', function () {
      var id = 0;
      $('.teacher-education-item').each(function (i, elem) {
        var _id = $(elem).attr('id').split('-').pop();

        if ($.isNumeric(_id) && _id > id) id = _id;
      });
      id++;
      var $row = $(myTutor.chunks.teacherUserPanel.education).attr('id', 'teacher-education-' + id).appendTo($('#education-list'));
      $row.find('input[name="education[][institution]"]').attr('name', 'education[' + id + '][institution]').val('');
      $row.find('input[name="education[][chair]"]').attr('name', 'education[' + id + '][chair]').val('');
      $row.find('input[name="education[][direction]"]').attr('name', 'education[' + id + '][direction]').val('');
      $row.find('.action-remove').attr('data-remove', '#teacher-education-' + id);
      return false;
    });

    if (location.hash) {
      $('a[href="' + location.hash + '"]').tab('show');

      if (location.hash == '#learner-active' || location.hash == '#learner-wait') {
        $('a[href="#my-learner"]').tab('show');
      }
    }

    $(document).on('shown.bs.tab', 'a[data-toggle="tab"]', function () {
      history.pushState(null, "", location.href.split('#')[0] + $(this).attr('href'));
    });
    $(document).on('submit', '.ajaxFormNonResponce', function () {
      $.ajax({
        url: $(this).attr('action'),
        method: $(this).attr('method'),
        data: $(this).serialize(),
        dataType: 'json',
        success: function success(res) {
          var message = '#services-saved-';

          if (!res.success) {
            message += 'fail';
          } else {
            res.ids.forEach(function (id, i) {
              var $item = $('.service-item').eq(i);
              $item.attr('id', 'service-' + id);
              $item.find('td').eq(1).html(id);
              $item.find('input[name^="services_id"]').attr('name', 'services_id[' + id + ']').val(id);
              $item.find('input[name^="services_name"]').attr('name', 'services_name[' + id + ']');
              $item.find('input[name^="services_price"]').attr('name', 'services_price[' + id + ']');
              $item.find('input[name^="services_active"]').attr('name', 'services_active[' + id + ']');
            });
            message += 'success';
          }

          $(message).fadeIn(300);
          setTimeout(function () {
            $(message).fadeOut(2000);
          }, 3000);
        }
      });
      return false;
    });
    $(document).on('click', '.toggler', function (e) {
      var selector = $(this).attr('data-toggle');
      $(selector).toggle();
    });
    /*$(document).on('submit', '#learner form', function () {
        if(!window.__register_conpidition_accepted__) {
            $('#learner-condition').modal('show');
            return false;
        }
    });
     $(document).on('submit', '#teacher form', function () {
        if(!window.__register_conpidition_accepted__) {
            $('#teacher-condition').modal('show');
            return false;
        }
    });*/
    // $(document).on('click', '#im-learner', function (e) {
    // 	window.__register_conpidition_accepted__ = false;
    // 	$('#learner-condition').modal('show');
    // });
    // $(document).on('click', '#im-teacher', function (e) {
    // 	window.__register_conpidition_accepted__ = false;
    // 	$('#teacher-condition').modal('show');
    // });

    $(document).on('click', '#learner-condition .btn-primary', function () {
      window.__register_conpidition_accepted__ = true;
      $('#learner-condition').modal('hide');
    });
    $(document).on('click', '#teacher-condition .btn-primary', function () {
      window.__register_conpidition_accepted__ = true;
      $('#teacher-condition').modal('hide');
    });
    $('#learner-condition,#teacher-condition').on('hidden.bs.modal', function (e) {
      if (!window.__register_conpidition_accepted__) {
        $('.tabs .tab-content').hide();
        $('.im-buttons').show();
      } else {}
    });
  });
  $(document).on('change', '.auto-translate', function (e) {
    /*window.__lastKeyUpNotRunTimerTick__ = 0;*/
    var $input = $(this);
    /*if(!window.__lastKeyUpNotRunTimer__) {
        window.__lastKeyUpNotRunTimer__ = true;
        var intervalId = setInterval(function () {
            window.__lastKeyUpNotRunTimerTick__++;
            if(window.__lastKeyUpNotRunTimerTick__ < 2) { return ; }*/

    $.ajax({
      url: '/ajax',
      method: 'POST',
      dataType: 'text',
      async: false,
      data: {
        action: 'other/translate',
        input: $input.val()
      },
      success: function success(res) {
        $input.val(res);
      }
    });
    /*clearInterval(intervalId);
    window.__lastKeyUpNotRunTimer__ = false;
    }, 1000);
    }*/
  });

  var __get = function __get(value, def) {
    if (_typeof(value) === (typeof undefined === "undefined" ? "undefined" : _typeof(undefined)) || value === false) {
      value = def;
    }

    return value;
  };

  var __add = function __add(arr, item, id) {
    var isAdd = false;
    arr.forEach(function (_item, i) {
      if (_item.value == item) {
        arr[i].rows.push(id);
        isAdd = true;
      }
    });

    if (!isAdd) {
      arr.push({
        value: item,
        rows: [id]
      });
    }
  };

  var tableFilter = function tableFilter(selector) {
    var $filters = $(selector);
    var tableSelector = $filters.attr('data-table');
    var $table = $(tableSelector);

    var offset = __get($filters.attr('data-offset'), 0);

    var allValues = {};
    $filters.find('.table-filter').each(function (i, filter) {
      var $filter = $(filter);
      $filter.html('');
      var columnId = $filter.attr('data-column');

      var type = __get($filter.attr('data-type'), 'text');

      var placeholder = __get($filter.attr('data-placeholder'), '');

      var aliases = __get($filter.attr('data-aliases'), '');

      if (aliases != '') {
        aliases = aliases.split('||');
        var _aliases = {};
        aliases.forEach(function (alias) {
          var alias = alias.split('==');
          var key = alias[0];
          var val = alias[0];

          if (alias.length > 1) {
            val = alias[1];
          }

          _aliases[key] = val;
        });
        aliases = _aliases;
      }

      var values = [];
      $table.find('tr').each(function (j, row) {
        if (j >= offset) {
          var $row = $(row);
          var $cell = $row.find('td').eq(columnId);

          var value = __get($cell.attr('data-value'), $cell.html());

          var delimeter = __get($cell.attr('data-delimeter'), false);

          if (delimeter) {
            value.split(delimeter).forEach(function (item) {
              __add(values, item, j);
            });
          } else {
            __add(values, value, j);
          }
        }
      });
      allValues['filter_' + columnId] = values;
      values.sort(function (a, b) {
        return a.value > b.value ? 1 : a.value < b.value ? -1 : 0;
      });

      switch (type) {
        case 'text':
          $('<input/>').addClass('form-control').attr({
            'placeholder': placeholder,
            'name': 'filter_' + columnId,
            'type': 'text'
          }).appendTo($filter);
          break;

        case 'list':
          var $options = '<option value="">' + placeholder + '</option>';
          values.forEach(function (item) {
            if (item.value != '') {
              var alias = item.value;

              if (aliases != '') {
                alias = aliases[item.value];
              }

              $options += '<option value="' + item.value + '">' + alias + '</option>';
            }
          });
          $('<select/>').addClass('form-control').attr({
            'name': 'filter_' + columnId
          }).html($options).appendTo($filter);
          break;
      }
    });
    tableFilter_process($filters, $table, allValues);
    $filters.find('input[type="text"]').on('keyup', function () {
      tableFilter_process($filters, $table, allValues);
    });
    $filters.find('select').on('change', function () {
      tableFilter_process($filters, $table, allValues);
    });
    /* Нажатие кнопки "Показать всё" */

    $('.table-filter-show-all[data-name="' + $filters.attr('data-table') + '"]').on('click', function () {
      if ($filters.attr('data-limit') == 0) {
        $(this).html("Показать всё");
        $filters.attr('data-limit', $(this).attr('data-limit'));
      } else {
        $(this).html("Свернуть");
        $(this).attr('data-limit', $filters.attr('data-limit'));
        $filters.attr('data-limit', 0);
      }

      tableFilter_process($filters, $table, allValues);
    });
  };

  var tableFilter_process = function tableFilter_process($filters, $table, values) {
    var filters = {};
    $filters.find('input,select').each(function (i, elem) {
      var $elem = $(elem);
      var $filter = $elem.parents('.table-filter');
      var columnId = $filter.attr('data-column');
      filters[columnId] = $elem.val();
    });

    var offset = __get($filters.attr('data-offset'), 0);

    var limit = __get($filters.attr('data-limit'), 10);

    var tableSelector = __get($filters.attr('data-table'), false);

    var item_num = 1;
    $table.find('tr').each(function (j, row) {
      if (j >= offset) {
        var isDisplay = true;
        var $row = $(row);

        for (var columnId in filters) {
          var val = filters[columnId];
          var _val = '';
          values['filter_' + columnId].forEach(function (v) {
            if (v.rows.indexOf(j) >= 0) {
              _val += v.value;
            }
          });

          if (_val.toLowerCase().indexOf(val.toLowerCase()) <= -1) {
            isDisplay = false;
          }
        }

        if (isDisplay && (item_num <= limit || limit == 0)) {
          $row.show();

          if (tableSelector == "#learner-list" || tableSelector == "#teacher-list" || tableSelector == "#learner-list-1") {
            item_num++;
          }
        } else {
          $row.hide();
        }
      }
    });
  };

  var tableFilter_initialize = function tableFilter_initialize() {
    $('.table-filters').each(function (i, elem) {
      tableFilter(elem);
    });
  };

  tableFilter_initialize();

  if (window.myTutor.hasOwnProperty('tableFilters')) {
    window.myTutor.tableFilters = {
      'init': function init(selector) {
        if (selector === undefined) {
          tableFilter_initialize();
        } else {
          tableFilter(selector);
        }
      }
    };
  }

  $(document).on('keyup', '#as0_advsea-search', function () {
    var value = $(this).val();
    var $resContain = $(this).parent().find('.results');

    if (value.length < 3) {
      return true;
    }

    $.ajax({
      url: '/ajax',
      method: 'post',
      dataType: 'json',
      data: {
        action: 'search/get',
        query: value
      },
      success: function success(res) {
        if (!res.length) {
          $resContain.hide(300);
        } else {
          $resContain.show(300);
        }

        var html = '';
        res.forEach(function (r) {
          html += '<a style="display: block;font-size: 18px; line-height: 22px;margin-bottom: 12px;" href="' + r.link + '">' + r.title + '</a>';
        });
        $resContain.html(html);
      }
    });
  });

  function hasFile(val) {
    var file = val.split('.');

    if (file.length != 2) {
      return false;
    }

    var extList = ["jpg", "jpeg", "png", "doc", "docx", "pdf", "gif", "PNG", "JPG", "JPEG", "GIF", "DOC", "DOCX", "PDF"];
    var result = false;
    extList.forEach(function (ext) {
      if (ext == file[1]) {
        result = true;
      }
    });
    return result;
  }

  function inputValidate(val) {
    if (val == "" || val.search('@') > 0) {
      return true;
    }

    if (hasFile(val)) {
      return true;
    }

    return true;
  }

  $(document).on('change', 'input[name="subjects[]"]', function (e) {
    var formGroup = $(e.target).closest('.form-group');
    var formGroupSubjects = formGroup.closest('.form-group-subjects');
    formGroupSubjects.find('input[type="text"]').closest('div').removeClass('required', false);

    if ($(e.target).prop('checked')) {
      formGroup.find('input[type="text"]').closest('div').addClass('required', true);
    }
  });
  $(document).on('submit', 'form', function (e) {
    $('.register-errors-contain').hide();
    $('.register-errors-contain .error-message').hide().removeClass('active');
    $(this).find('.form-group').removeClass('has-error');
    $(this).find('input:not([type="checkbox"]):not([type="hidden"]),textarea').each(function (i, inp) {
      if (!inputValidate($(inp).val())) {
        $(inp).parents('.form-group').addClass('has-error');
      }
    });

    if ($(this).find('.has-error').length != 0) {
      $(this).find('.has-error').get(0).scrollIntoView();
      $('.register-errors-contain .error-message.active').show();

      if ($('.register-errors-contain .error-message.active').length != 0) {
        $('.register-errors-contain').show();
      }

      e.stopPropagation();
      e.preventDefault();
      return false;
    }
  });

  var subjectList = function subjectList(width) {
    if (width < 768) {
      $('.subject-menu-list > li').each(function (index) {
        $('.subject-menu-list > li:eq(' + index + ') > ul').addClass('mobile-has-submenu');

        if ($('.subject-menu-list > li:eq(' + index + ') > ul').hasClass('mobile-has-submenu')) {
          $('.subject-menu-list > li:eq(' + index + ') > ul').data('index-sub-menu', index);
          $('.subject-menu-list > li:eq(' + index + ')').append('<i class="ic-sub-menu-item glyphicon glyphicon-chevron-right" data-index-sub-menu="' + index + '"></i>');
        }
      });
      $('.top-menu-list > li').each(function (index) {
        $('.top-menu-list > li:eq(' + index + ') > ul').addClass('mobile-has-main-submenu');

        if ($('.top-menu-list > li:eq(' + index + ') > ul').hasClass('mobile-has-main-submenu')) {
          $('.top-menu-list > li:eq(' + index + ') > ul').data('index-sub-menu', index);
          $('.top-menu-list > li:eq(' + index + ')').append('<i class="ic-main-sub-menu-item glyphicon glyphicon-chevron-right" data-index-sub-menu="' + index + '"></i>');
        }
      });
    } else {
      $('.subject-menu-list .ic-sub-menu-item').remove();
      $('.subject-menu-list').find('li ul').removeClass('mobile-has-submenu');
      $('.top-menu-list .ic-main-sub-menu-item').remove();
      $('.top-menu-list').find('li ul').removeClass('mobile-has-main-submenu');
    }
  };

  subjectList($(window).width());
  $(window).resize(function () {
    subjectList($(window).width());
  });
  $(document).on('click', '.ic-sub-menu-item', function () {
    var index = $(this).data('index-sub-menu'),
        a = $('.subject-menu-list > li:eq(' + index + ') > ul');
    a.toggle();

    if (a.is(':visible')) {
      $('.subject-menu-list > li:eq(' + index + ')').find('i').css('transform', 'rotate(90deg)');
    } else {
      $('.subject-menu-list > li:eq(' + index + ')').find('i').removeAttr('style');
    }
  });
  $('.top-menu-list > .root > a').on('click', function () {
    var i = $(this).parent().find('i');
    var index = $(i).data('index-sub-menu'),
        a = $('.top-menu-list > li:eq(' + index + ') > ul');
    a.toggle();

    if (a.is(':visible')) {
      $('.top-menu-list > li:eq(' + index + ')').find('i').css('transform', 'rotate(90deg)');
    } else {
      $('.top-menu-list > li:eq(' + index + ')').find('i').removeAttr('style');
    }
  });
  $(document).on('click', '.nav.nav-pills.nav-stacked > li a', function () {
    var body = $("html, body");
    body.stop().animate({
      scrollTop: $('#my-profile').offset().top
    }, '500');
  });
  $(document).on('click', '.teacher-filters button', function () {
    var body = $("html, body");
    body.stop().animate({
      scrollTop: $('#mse2_results').offset().top
    }, '500');
  });
  $('#teacher input,#teacher textarea').on('keydown', function (e) {
    if (e.key >= 'а' && e.key <= 'я' || e.key >= 'А' && e.key <= 'Я') {
      return false;
    }
  });
  $('.contacts-offer > a').magnificPopup({
    type: 'inline'
  });
  $('.comment-send').click(function () {
    $('#comment-form form input[name="userid"]').val($(this).attr("userid"));
  });
  $('.comment-edit').click(function () {
    $('#comment-edit-form form input[name="name"]').val($(this).parents('.reviews-item').find('.review-title').text());
    $('#comment-edit-form form textarea[name="message"]').val($(this).parents('.reviews-item').find('.review-text').text());
    $('#comment-edit-form form input[name="review_id"]').val($(this).attr("review_id"));
  });
  $('.comment-delete').click(function () {
    $('#comment-delete-form form input[name="review_id"]').val($(this).attr("review_id"));
  });
  $('.comment-send, .comment-edit, .comment-delete').magnificPopup({
    type: 'inline'
  });
  $('.teacher-reviews-btn').click(function () {
    var userid = $(this).attr('userid');
    $('.userid_' + userid).toggle();
  });
  $(document).on('af_complete', function (event, response) {
    if (response.success) {
      var form = response.form;
      var form_id = form.attr('id');
      var review_id = form.find("input[name=review_id]").val();

      if (form_id == 'comm-edit-form') {
        var name = form.find("input[name=name]").val();
        var message = form.find("textarea[name=message]").val();
        $('#review_' + review_id).find('.review-title').text(name);
        $('#review_' + review_id).find('.review-text').text(message);
      } else if (form_id == 'comm-delete-form') {
        $('#review_' + review_id).hide();
      }

      $('.mfp-close').click();
    }
  });
});

},{}]},{},[1]);
