/*!
 * ClockPicker v0.0.7 (http://weareoutman.github.io/clockpicker/)
 * Copyright 2014 Wang Shenwei.
 * Licensed under MIT (https://github.com/weareoutman/clockpicker/blob/gh-pages/LICENSE)
 */
!function () {
    function t(t) {
        return document.createElementNS(p, t)
    }

    function i(t) {
        return (10 > t ? "0" : "") + t
    }

    function e(t) {
        var i = ++m + "";
        return t ? t + i : i
    }

    function s(s, r) {
        function p(t, i) {
            var e = u.offset(), s = /^touch/.test(t.type), o = e.left + b, n = e.top + b,
                p = (s ? t.originalEvent.touches[0] : t).pageX - o, h = (s ? t.originalEvent.touches[0] : t).pageY - n,
                k = Math.sqrt(p * p + h * h), v = !1;
            if (!i || !(g - y > k || k > g + y)) {
                t.preventDefault();
                var m = setTimeout(function () {
                    c.addClass("clockpicker-moving")
                }, 200);
                l && u.append(x.canvas), x.setHand(p, h, !i, !0), a.off(d).on(d, function (t) {
                    t.preventDefault();
                    var i = /^touch/.test(t.type), e = (i ? t.originalEvent.touches[0] : t).pageX - o,
                        s = (i ? t.originalEvent.touches[0] : t).pageY - n;
                    (v || e !== p || s !== h) && (v = !0, x.setHand(e, s, !1, !0))
                }), a.off(f).on(f, function (t) {
                    a.off(f), t.preventDefault();
                    var e = /^touch/.test(t.type), s = (e ? t.originalEvent.changedTouches[0] : t).pageX - o,
                        l = (e ? t.originalEvent.changedTouches[0] : t).pageY - n;
                    (i || v) && s === p && l === h && x.setHand(s, l), "hours" === x.currentView ? x.toggleView("minutes", A / 2) : r.autoclose && (x.minutesView.addClass("clockpicker-dial-out"), setTimeout(function () {
                        x.done()
                    }, A / 2)), u.prepend(j), clearTimeout(m), c.removeClass("clockpicker-moving"), a.off(d)
                })
            }
        }

        var h = n(V), u = h.find(".clockpicker-plate"), v = h.find(".clockpicker-hours"),
            m = h.find(".clockpicker-minutes"), T = h.find(".clockpicker-am-pm-block"),
            C = "INPUT" === s.prop("tagName"), H = C ? s : s.find("input"), P = s.find(".input-group-addon"), x = this;
        if (this.id = e("cp"), this.element = s, this.options = r, this.isAppended = !1, this.isShown = !1, this.currentView = "hours", this.isInput = C, this.input = H, this.addon = P, this.popover = h, this.plate = u, this.hoursView = v, this.minutesView = m, this.amPmBlock = T, this.spanHours = h.find(".clockpicker-span-hours"), this.spanMinutes = h.find(".clockpicker-span-minutes"), this.spanAmPm = h.find(".clockpicker-span-am-pm"), this.amOrPm = "PM", r.twelvehour) {
            {
                var S = ['<div class="clockpicker-am-pm-block">', '<button type="button" class="btn btn-sm btn-default clockpicker-button clockpicker-am-button">', "AM</button>", '<button type="button" class="btn btn-sm btn-default clockpicker-button clockpicker-pm-button">', "PM</button>", "</div>"].join("");
                n(S)
            }
            n('<button type="button" class="btn btn-sm btn-default clockpicker-button am-button">AM</button>').on("click", function () {
                x.amOrPm = "AM", n(".clockpicker-span-am-pm").empty().append("AM")
            }).appendTo(this.amPmBlock), n('<button type="button" class="btn btn-sm btn-default clockpicker-button pm-button">PM</button>').on("click", function () {
                x.amOrPm = "PM", n(".clockpicker-span-am-pm").empty().append("PM")
            }).appendTo(this.amPmBlock)
        }
        r.autoclose || n('<button type="button" class="btn btn-sm btn-default btn-block clockpicker-button">' + r.donetext + "</button>").click(n.proxy(this.done, this)).appendTo(h), "top" !== r.placement && "bottom" !== r.placement || "top" !== r.align && "bottom" !== r.align || (r.align = "left"), "left" !== r.placement && "right" !== r.placement || "left" !== r.align && "right" !== r.align || (r.align = "top"), h.addClass(r.placement), h.addClass("clockpicker-align-" + r.align), this.spanHours.click(n.proxy(this.toggleView, this, "hours")), this.spanMinutes.click(n.proxy(this.toggleView, this, "minutes")), H.on("focus.clockpicker click.clockpicker", n.proxy(this.show, this)), P.on("click.clockpicker", n.proxy(this.toggle, this));
        var E, D, I, B, z = n('<div class="clockpicker-tick"></div>');
        if (r.twelvehour) for (E = 1; 13 > E; E += 1) D = z.clone(), I = E / 6 * Math.PI, B = g, D.css("font-size", "120%"), D.css({
            left: b + Math.sin(I) * B - y,
            top: b - Math.cos(I) * B - y
        }), D.html(0 === E ? "00" : E), v.append(D), D.on(k, p); else for (E = 0; 24 > E; E += 1) {
            D = z.clone(), I = E / 6 * Math.PI;
            var O = E > 0 && 13 > E;
            B = O ? w : g, D.css({
                left: b + Math.sin(I) * B - y,
                top: b - Math.cos(I) * B - y
            }), O && D.css("font-size", "120%"), D.html(0 === E ? "00" : E), v.append(D), D.on(k, p)
        }
        for (E = 0; 60 > E; E += 5) D = z.clone(), I = E / 30 * Math.PI, D.css({
            left: b + Math.sin(I) * g - y,
            top: b - Math.cos(I) * g - y
        }), D.css("font-size", "120%"), D.html(i(E)), m.append(D), D.on(k, p);
        if (u.on(k, function (t) {
            0 === n(t.target).closest(".clockpicker-tick").length && p(t, !0)
        }), l) {
            var j = h.find(".clockpicker-canvas"), L = t("svg");
            L.setAttribute("class", "clockpicker-svg"), L.setAttribute("width", M), L.setAttribute("height", M);
            var U = t("g");
            U.setAttribute("transform", "translate(" + b + "," + b + ")");
            var W = t("circle");
            W.setAttribute("class", "clockpicker-canvas-bearing"), W.setAttribute("cx", 0), W.setAttribute("cy", 0), W.setAttribute("r", 2);
            var N = t("line");
            N.setAttribute("x1", 0), N.setAttribute("y1", 0);
            var X = t("circle");
            X.setAttribute("class", "clockpicker-canvas-bg"), X.setAttribute("r", y);
            var Y = t("circle");
            Y.setAttribute("class", "clockpicker-canvas-fg"), Y.setAttribute("r", 3.5), U.appendChild(N), U.appendChild(X), U.appendChild(Y), U.appendChild(W), L.appendChild(U), j.append(L), this.hand = N, this.bg = X, this.fg = Y, this.bearing = W, this.g = U, this.canvas = j
        }
        o(this.options.init)
    }

    function o(t) {
        t && "function" == typeof t && t()
    }

    var c, n = window.jQuery, r = n(window), a = n(document), p = "http://www.w3.org/2000/svg",
        l = "SVGAngle" in window && function () {
            var t, i = document.createElement("div");
            return i.innerHTML = "<svg/>", t = (i.firstChild && i.firstChild.namespaceURI) == p, i.innerHTML = "", t
        }(), h = function () {
            var t = document.createElement("div").style;
            return "transition" in t || "WebkitTransition" in t || "MozTransition" in t || "msTransition" in t || "OTransition" in t
        }(), u = "ontouchstart" in window, k = "mousedown" + (u ? " touchstart" : ""),
        d = "mousemove.clockpicker" + (u ? " touchmove.clockpicker" : ""),
        f = "mouseup.clockpicker" + (u ? " touchend.clockpicker" : ""),
        v = navigator.vibrate ? "vibrate" : navigator.webkitVibrate ? "webkitVibrate" : null, m = 0, b = 100, g = 80,
        w = 54, y = 13, M = 2 * b, A = h ? 350 : 1,
        V = ['<div class="popover clockpicker-popover">', '<div class="arrow"></div>', '<div class="popover-title">', '<span class="clockpicker-span-hours text-primary"></span>', " : ", '<span class="clockpicker-span-minutes"></span>', '<span class="clockpicker-span-am-pm"></span>', "</div>", '<div class="popover-content">', '<div class="clockpicker-plate">', '<div class="clockpicker-canvas"></div>', '<div class="clockpicker-dial clockpicker-hours"></div>', '<div class="clockpicker-dial clockpicker-minutes clockpicker-dial-out"></div>', "</div>", '<span class="clockpicker-am-pm-block">', "</span>", "</div>", "</div>"].join("");
    s.DEFAULTS = {
        "default": "",
        fromnow: 0,
        placement: "bottom",
        align: "left",
        donetext: "完成",
        autoclose: !1,
        twelvehour: !1,
        vibrate: !0
    }, s.prototype.toggle = function () {
        this[this.isShown ? "hide" : "show"]()
    }, s.prototype.locate = function () {
        var t = this.element, i = this.popover, e = t.offset(), s = t.outerWidth(), o = t.outerHeight(),
            c = this.options.placement, n = this.options.align, r = {};
        switch (i.show(), c) {
            case"bottom":
                r.top = e.top + o;
                break;
            case"right":
                r.left = e.left + s;
                break;
            case"top":
                r.top = e.top - i.outerHeight();
                break;
            case"left":
                r.left = e.left - i.outerWidth()
        }
        switch (n) {
            case"left":
                r.left = e.left;
                break;
            case"right":
                r.left = e.left + s - i.outerWidth();
                break;
            case"top":
                r.top = e.top;
                break;
            case"bottom":
                r.top = e.top + o - i.outerHeight()
        }
        i.css(r)
    }, s.prototype.show = function () {
        if (!this.isShown) {
            o(this.options.beforeShow);
            var t = this;
            this.isAppended || (c = n(document.body).append(this.popover), r.on("resize.clockpicker" + this.id, function () {
                t.isShown && t.locate()
            }), this.isAppended = !0);
            var e = ((this.input.prop("value") || this.options["default"] || "") + "").split(":");
            if ("now" === e[0]) {
                var s = new Date(+new Date + this.options.fromnow);
                e = [s.getHours(), s.getMinutes()]
            }
            this.hours = +e[0] || 0, this.minutes = +e[1] || 0, this.spanHours.html(i(this.hours)), this.spanMinutes.html(i(this.minutes)), this.toggleView("hours"), this.locate(), this.isShown = !0, a.on("click.clockpicker." + this.id + " focusin.clockpicker." + this.id, function (i) {
                var e = n(i.target);
                0 === e.closest(t.popover).length && 0 === e.closest(t.addon).length && 0 === e.closest(t.input).length && t.hide()
            }), a.on("keyup.clockpicker." + this.id, function (i) {
                27 === i.keyCode && t.hide()
            }), o(this.options.afterShow)
        }
    }, s.prototype.hide = function () {
        o(this.options.beforeHide), this.isShown = !1, a.off("click.clockpicker." + this.id + " focusin.clockpicker." + this.id), a.off("keyup.clockpicker." + this.id), this.popover.hide(), o(this.options.afterHide)
    }, s.prototype.toggleView = function (t, i) {
        var e = !1;
        "minutes" === t && "visible" === n(this.hoursView).css("visibility") && (o(this.options.beforeHourSelect), e = !0);
        var s = "hours" === t, c = s ? this.hoursView : this.minutesView, r = s ? this.minutesView : this.hoursView;
        this.currentView = t, this.spanHours.toggleClass("text-primary", s), this.spanMinutes.toggleClass("text-primary", !s), r.addClass("clockpicker-dial-out"), c.css("visibility", "visible").removeClass("clockpicker-dial-out"), this.resetClock(i), clearTimeout(this.toggleViewTimer), this.toggleViewTimer = setTimeout(function () {
            r.css("visibility", "hidden")
        }, A), e && o(this.options.afterHourSelect)
    }, s.prototype.resetClock = function (t) {
        var i = this.currentView, e = this[i], s = "hours" === i, o = Math.PI / (s ? 6 : 30), c = e * o,
            n = s && e > 0 && 13 > e ? w : g, r = Math.sin(c) * n, a = -Math.cos(c) * n, p = this;
        l && t ? (p.canvas.addClass("clockpicker-canvas-out"), setTimeout(function () {
            p.canvas.removeClass("clockpicker-canvas-out"), p.setHand(r, a)
        }, t)) : this.setHand(r, a)
    }, s.prototype.setHand = function (t, e, s, o) {
        var c, r = Math.atan2(t, -e), a = "hours" === this.currentView, p = Math.PI / (a || s ? 6 : 30),
            h = Math.sqrt(t * t + e * e), u = this.options, k = a && (g + w) / 2 > h, d = k ? w : g;
        if (u.twelvehour && (d = g), 0 > r && (r = 2 * Math.PI + r), c = Math.round(r / p), r = c * p, u.twelvehour ? a ? 0 === c && (c = 12) : (s && (c *= 5), 60 === c && (c = 0)) : a ? (12 === c && (c = 0), c = k ? 0 === c ? 12 : c : 0 === c ? 0 : c + 12) : (s && (c *= 5), 60 === c && (c = 0)), this[this.currentView] !== c && v && this.options.vibrate && (this.vibrateTimer || (navigator[v](10), this.vibrateTimer = setTimeout(n.proxy(function () {
            this.vibrateTimer = null
        }, this), 100))), this[this.currentView] = c, this[a ? "spanHours" : "spanMinutes"].html(i(c)), !l) return void this[a ? "hoursView" : "minutesView"].find(".clockpicker-tick").each(function () {
            var t = n(this);
            t.toggleClass("active", c === +t.html())
        });
        o || !a && c % 5 ? (this.g.insertBefore(this.hand, this.bearing), this.g.insertBefore(this.bg, this.fg), this.bg.setAttribute("class", "clockpicker-canvas-bg clockpicker-canvas-bg-trans")) : (this.g.insertBefore(this.hand, this.bg), this.g.insertBefore(this.fg, this.bg), this.bg.setAttribute("class", "clockpicker-canvas-bg"));
        var f = Math.sin(r) * d, m = -Math.cos(r) * d;
        this.hand.setAttribute("x2", f), this.hand.setAttribute("y2", m), this.bg.setAttribute("cx", f), this.bg.setAttribute("cy", m), this.fg.setAttribute("cx", f), this.fg.setAttribute("cy", m)
    }, s.prototype.done = function () {
        o(this.options.beforeDone), this.hide();
        var t = this.input.prop("value"), e = i(this.hours) + ":" + i(this.minutes);
        this.options.twelvehour && (e += this.amOrPm), this.input.prop("value", e), e !== t && (this.input.triggerHandler("change"), this.isInput || this.element.trigger("change")), this.options.autoclose && this.input.trigger("blur"), o(this.options.afterDone)
    }, s.prototype.remove = function () {
        this.element.removeData("clockpicker"), this.input.off("focus.clockpicker click.clockpicker"), this.addon.off("click.clockpicker"), this.isShown && this.hide(), this.isAppended && (r.off("resize.clockpicker" + this.id), this.popover.remove())
    }, n.fn.clockpicker = function (t) {
        var i = Array.prototype.slice.call(arguments, 1);
        return this.each(function () {
            var e = n(this), o = e.data("clockpicker");
            if (o) "function" == typeof o[t] && o[t].apply(o, i); else {
                var c = n.extend({}, s.DEFAULTS, e.data(), "object" == typeof t && t);
                e.data("clockpicker", new s(e, c))
            }
        })
    }
}();